/**
 * Import 3rd Party Partials
 */
@import "../fontello/css/fontello";
@import "../fontello/css/animation";

@import "partials/landingpage/bootstrap.min";
@import "partials/landingpage/font-awesome.min";
@import "partials/landingpage/et-line-fonts";
@import "partials/landingpage/animate";
@import "partials/landingpage/hover";
@import "partials/landingpage/magnific-popup";
@import "partials/landingpage/owl.carousel";
@import "partials/landingpage/owl.transitions";
@import "partials/landingpage/main";

/**
 * Landingpage Styles
 */
@import "partials/_landingpage";