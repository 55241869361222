/****************/
/* Theme Tweaks */
/****************/

.masthead-image{
    background: url(../img/headart-cover-1.jpg) 50% 50% no-repeat;
    background-size: cover;
}

.brand {
    font-weight: 300;
    line-height: inherit;
}

.section {
    padding-top: 100px;
    padding-bottom: 100px;
}

.form-control {
    color: #000;
}

.bgc-dark {
    background: #000;
}

@media screen and (max-width : 766px) {
    .opener {
        padding: 175px 0 100px;
    }
}

/*****************/
/* Custom Shtuff */
/*****************/

.about a {
    color: #808080;
}

.about a:hover,
.about a:active {
    color: #D4B068;
}

.logo {
    height: 50px;
    margin-top: -3px;
}
    .affix-top .logo {
        height: 85px;
        margin-top: 5px;   
    }

.gold {
    color: #D4B068;
}

.thin {
    font-weight: 100;
}

.upper {
    text-transform: uppercase;
}

.fw-300 {
    font-weight: 300;
}

.bold {
    font-weight: 600;
}

.subtitle {
  padding-top: 20px;
}

.cover {
    display: block;
    text-decoration: none !important;
}

.underline {
    display: inline-block;
    padding-bottom: 22px;
    border-bottom: 1px solid #D4B068;
}

.newsletter-form {
    margin-top: 25px;
}

.fb-box {
    margin-top: 70px;
}

.icon.small {
    margin: 18px 0 10px;
    font-size: 28px;
}
.team-profile .social .icon {
    margin: 0 3px;
    font-size: 32px;
}